.slider {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s ease;
}

.slider-text {
  height: 100%;
}

.owl_item .slider-text h1 {
  font-size: 46px;
  color: #fff;
  line-height: 1.2;
  font-weight: 600;
}

.owl_item .slider-text p {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 2rem;
}

.slide .container {
  z-index: 99;
}

.slider_bg_ctrl {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 100%;
  position: relative;
  z-index: 0;
  display: flex;
}

.owl_item .btn_get.btn_hover.btn_fill {
  background: #fd813a;
}

.mbr-section-btn .btn_get {
  margin: 0 10px 0 0;
  color: #ffffff;
  border: 1px solid #fd813a;
  padding: 10px 16px;
  width: auto;
  height: auto;
  min-width: auto;
  line-height: initial;
}

.owl_item .btn_get.btn_hover.btn_fill:hover {
  color: #fd813a;
  background: #ffffff;
  border: 1px solid #ffffff;
}

.owl_item .btn_get.btn_hover.btn_ghost:hover {
  background-color: #fd813a;
}

.owl-dot.active {
  background-color: #fd813a;
}

.owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  width: 100%;
  text-align: center;
  z-index: 9999;
}

.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #e1e1e1;
  margin: 0 5px;
  border: 0;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}

.slide img {
  width: 100%;
  height: 100%;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.content {
  position: absolute;
  top: 23rem;
  left: 10rem;
  opacity: 0;
  /*width: 50%;*/
  color: #fff;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  animation: slide-up 1s ease 0.5s;
  /* animation-delay: 1s; */
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 23rem;
  }

  100% {
    visibility: visible;
    top: 17rem;
  }
}

@media screen and (max-width: 600px) {
  .content {
    width: 80%;
  }
}

@media (max-width: 991.98px) {
  .owl_item .slider-text h1 {
    font-size: 36px;
  }
}

@media (max-width: 767.98px) {
  .slider-text {
    text-align: center;
    padding: 0 30px;
  }

  .slider .arrow {
    height: 1.5rem;
    width: 1.5rem;
  }

  .slider .prev {
    left: 0.5rem;
  }

  .slider .next {
    right: 0.5rem;
  }

  .mbr-section-btn .btn_get {
    padding: 8px 12px;
  }

  .owl_item .slider-text p {
    font-size: 16px;
  }
}

.content>* {
  color: #fff;
  margin-bottom: 1rem;
}

.current .content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.arrow {
  border: 2px solid white;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: absolute;
  z-index: 999;
}

.arrow:hover {
  background-color: #fff;
  color: #777;
}

.next {
  top: 50%;
  right: 2.5rem;
}

.prev {
  top: 50%;
  left: 2.5rem;
}

hr {
  height: 2px;
  background: white;
  width: 50%;
}