.cardbg{
    background-color: #fff;
    /* padding-left: 250px; */
    /* padding-right: 50px; */
    margin-bottom: -30px;
    padding-bottom: 70px;
    /* margin-left: 20px; */
  }

.card-group{
  display: flex;
  justify-content: space-evenly;
}

.card{
    padding: 15px;
    margin-top: 30px;
    margin-right: 15px;
    border: 1px solid #f2f5f8;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
    border-radius: 10px;
    transition: .3s all ease-in-out;
}

.card:hover{
  transform: scale(1.03);
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}