.main1{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DBE9FA;
}
.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .social-icons .fb {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #fff;
    margin: 20px;
    text-align: center;
    font-size: 50px;
    line-height: 100px;
    font-family: sans-serif;
    overflow: hidden;
    box-shadow: 5px 10px 20px rgba(150, 150, 150, .3);
    transition: all .3s ease-out;
    color: #1A6ED8;
  }
  
  .social-icons .fb:hover {
    width: 400px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #fff
  }

    .fb:hover{
    background: #1A6ED8;
  }

  .social-icons .insta {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #fff;
    margin: 20px;
    text-align: center;
    font-size: 50px;
    line-height: 100px;
    font-family: sans-serif;
    overflow: hidden;
    box-shadow: 5px 10px 20px rgba(150, 150, 150, .3);
    transition: all .3s ease-out;
    color: #d62976;
  }
  
  .social-icons .insta:hover {
    width: 400px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #fff
  }

    .insta:hover{
    background: #d62976 ;
  }

  .social-icons .linkedin {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #fff;
    margin: 20px;
    text-align: center;
    font-size: 50px;
    line-height: 100px;
    font-family: sans-serif;
    overflow: hidden;
    box-shadow: 5px 10px 20px rgba(150, 150, 150, .3);
    transition: all .3s ease-out;
    color: #0072b1 ;
  }
  
  .social-icons .linkedin:hover {
    width: 400px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #fff
  }

    .linkedin:hover{
    background: #0072b1  ;
  }

  .social-icons .whatsapp {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #fff;
    margin: 20px;
    text-align: center;
    font-size: 50px;
    line-height: 100px;
    font-family: sans-serif;
    overflow: hidden;
    box-shadow: 5px 10px 20px rgba(150, 150, 150, .3);
    transition: all .3s ease-out;
    color: #128C7E  ;
  }
  
  .social-icons .whatsapp:hover {
    width: 400px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #fff
  }

    .whatsapp:hover{
    background: #128C7E   ;
  }
  /* Responsive Styles */
  @media (max-width: 768px) {
    .social-icons {
      flex-direction: column;
      text-align: center;
    }
  
    .social-icons a {
      margin: 10px 0;
    }
  }