body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.button{
  color: #FFFFFF;
  background-color: #1CB23D;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
}
.qty{

}
.nav{
  display: flex;
}
.nav .cat{
  color: #000000;
}
.nav .hom{
  color: #0703C3;
}
.nav .pro{
  color: #0703C3;
}
.para{
  color: #000000;
  font-size: 10px;
}
.relatedPro{
  margin-left: 50px;
  color: #000000;
}
.second{
  background-color: #F3F3FF;
  margin-top: -15px;
}
.img{
  margin-top: 30px;
  margin-left: 55px;
  margin-right: 50px;
}
.imgfolder{
  display: flex;
}
.name{
  color: #000000;
  margin-top: 10px;
  margin-left: 20px;
}





.table{
  margin-left: 130px;
}
/* .up{
  margin-top: 20px;
} */
.riz{
  display: flex;
  margin-top: 20px;
}
.card-title{
  margin-left: 15px;
}
.tar{
  color: #626060;
  margin-left: 15px;
  /* margin-top: 1000px; */
  margin-bottom: 10px;
}
.tbl{
  display: flex;
}
.space{
  /* margin-left: 10px; */
  margin-right: 30px;
  color: #000000;
}

.check{
  /* background-color: #1CB23D; */
  /* color: #F3F3FF; */
  
}
.sub{
  color: #000000;
}




.NP{
  display: flex;
}
.Tele{
  margin-left: 80px;
}
.two{
  display: flex;
}



.fom{
margin-left: 130px;
size: 100px;
}
.head{
  margin-left: 130px;
  
}

.shippin-gmethode{
  margin-left: 130px;
}
.text-center{
  margin-top: 10px;
 
}
.table{
  /* margin-bottom: 20px; */
  margin-top: -200px;
  margin-left: 10px;
}
.payment{
  margin-left: 550px;
  margin-top: 10px;
}
.image{
  margin-left: 45px;
  margin-right: 45px;
}
.cardbg{
  background-color: #F3F3FF;
}