/* OrderTracking.css */
.order-tracking {text-align: center; padding: 5px; position: relative}
h4.fw-bold{padding-left:0px;}
.order-heading-section button.common-btn1 svg{margin-left:5px}
.status-container {display: flex;justify-content: space-between; margin: 5px;}
.status {
  width: 90px;
  height: 90px;
  border: 5px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}
.status-container:after {
  border-bottom: 5px solid #ccc;
  content: '';
  display: block;
  width: 90%;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.status.active {
  background-color: rgba(5, 147, 229, 0.861);
  color: #fff;
  border-color: rgba(5, 147, 229, 0.861);
}

button {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 24px;
}

button:hover {
  background-color: #2980b9;
}
.order-status{padding-left:15px;}
